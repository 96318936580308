.loader-screen {
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color: $red;
  display: flex;
  flex-direction: column;
  align-items: center;
  .hink-logo {
    display: inline-block;
    height: 83px;
    width: 168px;
    background-image: url('../assets/i/hink-logo-white.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 55%;
  }
  .main-loader-text {
    margin-top: 82px;
    .first-line {
      font-family: Manrope, sans-serif;
      font-weight: 500;
      font-size: 30px;
      line-height: 110%;
      text-align: center;
      color: #FFFFFF;
    }
    .hink {
      font-family: 'pf-regular-pro', serif;
      font-style: normal;
      font-weight: 900;
      font-size: 60px;
      line-height: 39px;
      color: #FFFFFF;
    }
  }

  @keyframes fade-hinkali {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .finder-loading-animation {
    position: relative;
  }

  .finder-loading-animation-container .finder-loading-animation {
    position: fixed;
    left: 50%;
    top: 65%;
    width: 57px;
    height: 57px;
    margin: auto;
    z-index: 99999;
  }
  //.finder-loading-animation-container.content-loaded .finder-loading-animation {
  //  opacity: 1;
  //  filter:alpha(opacity=100);
  //  zoom: 1;
  //  visibility: hidden;
  //}
  .spinner {
    position: absolute;
    display: block;
    animation-name: fade-hinkali;
    animation-duration: 2.1s;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
  #hink-1 {
    left: 48px;
    top: -3px;
    animation-delay: 0.41s;
    background: url('../assets/i/loader-hinks/hink-1.svg');
    width: 11px;
    height: 9px;
  }
  #hink-2 {
    left: 69px;
    top: 22px;
    animation-delay: 0.55s;
    background: url('../assets/i/loader-hinks/hink-2.svg');
    width: 16px;
    height: 13px;
  }
  #hink-3 {
    top: 64px;
    left: 66px;
    animation-delay: 0.69s;
    background: url('../assets/i/loader-hinks/hink-3.svg');
    width: 21px;
    height: 17px;
  }
  #hink-4 {
    right: -10px;
    top: 99px;
    animation-delay: 0.83s;
    background: url('../assets/i/loader-hinks/hink-4.svg');
    width: 26.3px;
    height: 21.18px;
  }
  #hink-5 {
    right: 37px;
    top: 121px;
    animation-delay: 0.97s;
    background: url('../assets/i/loader-hinks/hink-5.svg');
    width: 36.83px;
    height: 29.65px;
  }
  #hink-6 {
    right: 98px;
    bottom: -74px;
    animation-delay: 1.1s;
    background: url('../assets/i/loader-hinks/hink-6.svg');
    width: 36.83px;
    height: 29.65px;
  }
  #hink-7 {
    left: -98px;
    bottom: -21px;
    animation-delay: 1.24s;
    background: url('../assets/i/loader-hinks/hink-7.svg');
    width: 36.83px;
    height: 29.65px;
  }
  #hink-8 {
    left: -86px;
    bottom: 33px;
    animation-delay: 1.38s;
    background: url('../assets/i/loader-hinks/hink-8.svg');
    width: 42.09px;
    height: 33.88px;
  }
  #hink-9 {
    left: -26px;
    bottom: 58px;
    animation-delay: 1.52s;
    background: url('../assets/i/loader-hinks/hink-9.svg');
    width: 47px;
    height: 38px;
  }

  //.finder-loading-animation-container {
  //  position: absolute;
  //  z-index: 99999;
  //  left: 0;
  //  right: 0;
  //  width: 100%;
  //  bottom: 30%;
  //}
}
