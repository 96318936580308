//adress_info
.adress_info {
    display: flex;
    font-size: 10px;
    justify-content: space-between;
    padding-top: 20px;
    cursor: pointer;
    font-family: $regular;

    &__city,
    &__phone {
        color: $gray6;
    }
}

.carousel-row {
    font-family: $regular;
}

//header
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 11px;
    &__burger-menu {
        background-image: url("../assets/i/menu.svg");
        background-size: 29px 19px;
    }
    &__logo img {
        width: 118px;
        height: 48px;
    }
    &__search {
        background-image: url("../assets/i/search.svg");
        background-size: 29px 28px;

        &-window {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 99999;
        }
        &-list {
            position: relative;
            z-index: 9999;
            background-color: $grayEF;
            height: 100%;
            padding-bottom: 40px;
            padding-top: 20px;
        }
        &-field {
            display: flex;
            align-items: flex-end;
            padding-bottom: 34px;
            height: 104px;
            background-color: #fff;
            width: 100%;
            box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.04),
                0px 2.6963px 5.0963px rgba(0, 0, 0, 0.0242963),
                0px 0.57037px 1.3037px rgba(0, 0, 0, 0.0157037);

            .container {
                width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &__close,
            &__search {
                cursor: pointer;
            }
            input {
                border: none;
                outline: none;
                margin-right: auto;
                margin-left: 20px;
                font-family: $regular;
                font-size: 21px;

                &::placeholder {
                    font-family: $regular;
                    font-size: $fsprice;
                }
            }
        }
    }
    &__burger-menu,
    &__search {
        width: 72px;
        cursor: pointer;
        background-position: center;
        height: 48px;
        background-repeat: no-repeat;
    }
}

//categories
.categories {
    border-bottom: 1px solid #dedede;
    padding-bottom: 20px;
    position: sticky;
    top: -10px;
    padding-top: 20px;
    background-color: #efefef;
    z-index: 999;
    user-select: none;

    .carousel-initialized {
        overflow: initial;
    }

    &__item {
        cursor: pointer;
        height: 75px;

        &__content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            outline: none;
        }
        &__title {
            margin-top: 13px;
            color: $gray3;
            text-align: center;
        }
        &__img-wrap {
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                width: 28px;
                overflow: hidden;
            }
            img {
                height: 28px;
                margin-left: 0;
            }
        }

        &.active {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $red;
                bottom: -21px;
                border-radius: 1.5em;
            }
            .categories__item__title {
                margin-top: 0;
                font-size: $fstitle;
                font-family: $bold;
                color: $red;
            }
            .categories__item__img-wrap {
                width: 60px;
                height: 60px;
                background-color: $red;
                border-radius: 15px;

                img {
                    margin-left: -100%;
                }
            }
        }
    }
}

.link-to-cart {
    background: $blue;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06);
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $bold;
    color: $white;
    position: fixed;
    bottom: calc((100vh - 86vh) - 15px);
    width: 100%;
    max-width: 375px;
    min-height: 72px;
    cursor: pointer;
    overflow: hidden;
    z-index: 9999;
    text-decoration: none;
    transition: all 0.2s ease-in;
    &__empty {
        cursor: default;
        background-color: $white;
        position: absolute;
        bottom: -300px;
        left: 0;
        width: 100%;
        transition: bottom 0.25s ease-in;
        background-image: url("../assets/img/starik.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        padding: 30px 20px;
        min-height: 286px;
        color: $gray3;
        border-radius: 30px 30px 0 0;
        filter: drop-shadow(19.8311px 23.8271px 60px rgba(19, 42, 62, 0.2))
            drop-shadow(
                7.63863px 9.17783px 19.1111px rgba(19, 42, 62, 0.121481)
            )
            drop-shadow(
                1.61586px 1.94146px 4.88889px rgba(19, 42, 62, 0.0785185)
            );

        &.open {
            bottom: 0;
        }
        h1 {
            font-size: 21px;
        }
        p {
            font-size: $fstitle;
            margin-top: 10px;
        }
    }
    .container {
        width: 100%;
    }
    &__count {
        font-size: 21px;
    }
    &__text {
        font-size: $fstitle;
        margin-right: auto;
        margin-left: 15px;
    }
    &__booking {
        height: 26px;
        width: 52px;
        background-image: url("../assets/i/empty_cart.svg");
        opacity: 0.5;
        filter: brightness(0) invert(1);
    }
}
.link-to-cart.open {
    min-height: 300px;
    background-color: transparent;
    &.overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: rgba(19, 42, 62, 0.75);
        z-index: 999;
    }
}

.link-to-cart.open .link-to-cart__empty {
    bottom: 0;
    height: 300px;
}

.header-back {
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05),
        0px 5px 10px rgba(0, 0, 0, 0.05), 0px 3px 5px rgba(0, 0, 0, 0.05);
    padding: 30px 0;
    position: relative;
    z-index: 2;

    &__return-btn {
        background: transparent;
        border: none;
        position: absolute;
        left: 0;
        cursor: pointer;
    }
    &__content {
        font-family: $bold;
        font-size: 17px;
        line-height: 20px;
        color: $gray3;
    }
}

.btn {
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.adress_fild {
    .form__field-wrapper:not(:last-child) {
        border: none;
    }
    .from__box-adress {
        padding-bottom: 16px;
        .form__field-wrapper__input::placeholder,
        .form__field-wrapper__input {
            font-size: 12px;
            text-align: center;
        }
    }
    &__address {
        cursor: pointer;
        margin-left: 13px;
        font-size: 12px;
    }
}
.addfild {
    .row {
        flex-wrap: wrap;
    }
}
.administrator {
    font-family: $bold;
    margin-top: 30px;
    font-size: 13px;
}
.addnew_cart {
    width: 100%;
    background: #efefef;
    border-radius: 8px;
    margin-top: 10px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
        margin-left: 10px;
        color: #333;
        font-size: 13px;
        font-family: $bold;
    }
}
.form {
    display: flex;
    flex-direction: column;

    &__create {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 30px;
    }

    &__textarea {
        resize: none;
        outline: none;
        border: none;
        width: 100%;
        border-radius: 10px;
        min-height: 60px;
        padding: 12px;
        background-color: $grayEF;

        &::placeholder {
            font-family: $regular;
            font-size: $fstitle;
            color: $gray6;
        }
    }
    &__field-wrapper {
        align-items: center;
        position: relative;
        margin-bottom: 7px;
        &__address {
            width: 100%;
            background: #EFEFEF;
            border-radius: 90px;
            display: flex;
            align-items: center;
            height: 40px;
            input {
                margin-left: 15px;
            }
        }

        &.error &__error {
            margin-top: 15px;
            font-size: 10px;
            color: #ff0000;
        }
        &.error {
            background-color: #ff00001a;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 3px;
                bottom: 0;
                background-color: #ff0000;
                z-index: 999;
            }
            .form__field-wrapper__placeholder__title {
                color: #ff0000;
            }
        }

        &.not-valid .row {
            filter: grayscale(1);
        }
        &__input {
            background-color: #EFEFEF !important;
            border: none;
            outline: none;
            font-family: $regular;
            width: 85%;
            padding: 5px 0;

            &::placeholder {
                color: #ABABAB;
                font-family: $regular;
                font-size: 12px;
            }
            &:focus {
                background-color: $grayEF;
            }

            &.gray {
                background-color: #EFEFEF;
                padding: 11px;
                width: 24%;
                border-radius: 60px;
                height: 40px;
                &.floor {
                    width: 18%;
                }
            }
        }
        &__title {
            color: #666666;
            font-size: 13px;
            line-height: 120%;
            margin-bottom: 7px;
            font-family: sf-ui-medium, sans-serif;
        }
        &__placeholder {
            display: flex;
            align-items: center;

            &__ico {
                width: 26px;
                img {
                    margin: 13px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            &__title {
                color: $red;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 88px;
                &:after {
                    content: "";
                    width: 1px;
                    height: 16px;
                    background-color: $grayC;
                    margin: 0 15px;
                }
            }
        }
    }
    &__field {
        width: 100%;

        &__values {
            position: absolute;
            color: $gray3;
            font-family: $bold;
            font-size: $fstitle;
            left: 0;
            top: 100%;
            width: 100%;
            background-color: $grayEF;
            z-index: 99999;
            border-radius: 0px 0px 15px 15px;
            overflow: hidden;
            height: 0;
            transition: max-height 0.25s ease-in;

            &.open {
                height: auto;
            }

            &__item {
                padding: 15px 22px;
                cursor: pointer;

                &.active {
                    background-color: $red;
                    color: $white;
                }
            }
        }
        &__open {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $grayEF;
            width: 30px;
            height: 30px;
            border-radius: 8px;
            cursor: pointer;

            img {
                transition: transform 0.15s ease-in;
            }
            &.open {
                background-color: $red;

                img {
                    filter: brightness(0) invert(1);
                    transform: rotate(-90deg);
                }
            }
        }
        &__type {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            cursor: pointer;
        }
        &__value {
            //color: $gray3;
            font-family: $bold;
            font-size: $fstitle;
            //white-space: pre-line;

            &-street {
                font-family: $regular;
                margin-top: 0.5px;
            }
        }
    }
}

.adress_fild .form__field-wrapper {
    z-index: 55;
}
.ymaps-2-1-79-search__suggest.ymaps-2-1-79-search__suggest {
    box-shadow: none !important;
}

//favorites
.favorite_empty {
    background: #efefef;
    border: 3px solid #cccccc;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 30px;
    margin-top: 15px;
    &__title {
        font-size: 21px;
        line-height: 120%;
        color: #000000;
        font-family: "sf-ui-bold";
        font-weight: bold;
    }
    &__title-red {
        display: block;
        color: #8d191d;
    }
    &__img {
        margin-top: 15px;
    }
}

.custom_checkbox {
    display: flex;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;

    input[type="checkbox"] {
        display: none;

        &:checked + span {
            background-image: url("../assets/i/checked-red.svg");
            background-repeat: no-repeat;
            background-size: 15px 12px;
            background-position: center;
        }
    }
    span {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        border-radius: 8px;
        border: 2px solid $grayC;
    }
}

._404 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: radial-gradient(
            50% 50% at 50% 50%,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        url("../assets/img/_404-bg.png");
    background-position: center, -27.5px 188.4px;

    h1 {
        color: $red;
        font-size: 129px;
        font-family: $bold;
        letter-spacing: 4px;
    }
    &__content {
        text-align: center;
        color: $gray3;
        display: flex;
        flex-direction: column;
        font-size: $fsprice;

        b {
            font-family: $bold;
            margin-bottom: 2px;
        }
    }
}

.about {
    height: 100%;
    min-height: 86vh;
    background-image: url("../assets/img/menu-background_tile.png");
    background-repeat: repeat no-repeat;
    background-position: bottom;
    overflow-y: auto;
    & > .container {
        display: flex;
        flex-direction: column;
    }
    &__content {
        color: #000000;
        margin-top: 20px;
        font-size: $fsstocktitle;
        line-height: 140%;

        p {
            text-indent: 25px;
        }
    }
    .condition {
        color: $gray6;
        line-height: 140%;
        max-width: 161px;
        text-align: center;
        margin: 20px auto 0 auto;
    }
}

.border_dotted-circle {
    display: flex;
    justify-content: space-between;

    span {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $red;
    }
}

.address-select-map {
    position: fixed;
    width: inherit;
    top: 15px;
    bottom: 0;
    max-width: inherit;
    background-color: $white;
    z-index: 999;
    max-height: 86vh;
    overflow: hidden;

    &.map {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    //button {
    //    position: absolute;
    //    bottom: 0;
    //    width: 100%;
    //    background-color: $red;
    //    left: 50%;
    //    transform: translateX(-50%);
    //    border: none;
    //    outline: none;
    //    padding: 26px 0;
    //    font-family: $bold;
    //    color: $white;
    //    cursor: pointer;
    //    text-transform: uppercase;
    //    font-size: 18px;
    //    min-width: 280px;
    //    max-width: 340px;
    //}
}

.server-error {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid $red;
    color: $red;
    font-size: 11px;
    line-height: 120%;
    padding: 16px;
    background-color: rgba(255, 0, 0, 0.1);
}
@media only screen and (max-width: 560px) and (orientation: portrait) {
    .address-select-map {
        top: 0;
        max-height: 100%;
        overflow: initial;
    }
    .about {
        min-height: 100vh;
    }
}
