$red:#8D191D;
$green: #558950;
$blue: #132A3E;
$grayEF: #EFEFEF;
$grayAB: #ABABAB;
$gray9:#999999;
$gray6:#666666;
$gray3:#333333;
$grayC:#CCCCCC;
$grayDE:#DEDEDE;
$white: #FFFFFF;


//fonts
$thin: 'sf-ui-thin';
$semibold: 'sf-ui-semibold';
$bold: 'sf-ui-bold';
$black: 'sf-ui-black';
$regular: 'sf-ui-regular';


//sizes
$fstext: 11px;
$fstitle: 13px;
$fsstocktitle: 15px;
$fsprice: 17px;
