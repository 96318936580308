.bankCard_box{
    background: #FFFFFF;
    box-shadow: 0px 16px 29px rgba(0, 0, 0, 0.1), 0px 6.16296px 9.23704px rgba(0, 0, 0, 0.0607407), 0px 1.3037px 2.36296px rgba(0, 0, 0, 0.0392593);
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 30px;
    .bankCard_iconcard{
        display: flex;
        justify-content: space-around;
    }
}
.bankCard_wrapp-bar{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.bankCard_wrapp{
    margin-top: 10px;
    .bankCard_wrapp-lable{
        color: #666666;
        font-size: 11px;
        margin-bottom: 8px;
    }
    .bankCard_wrapp__boxinput{
        display: flex;
        align-items: center;
    }
    .bankCard_wrapp-input{
        background: #EFEFEF;
        border-radius: 8px;
        padding: 10px;
        border: none;
        color: #333;
        outline: none;
        font-family: $bold;
        width: 63px;
        margin-right: 8px;
        text-align: center;
        &:last-child{
            margin-right: 0;
        }
    }
    span{
        margin-right: 8px;
        font-size: 14px;
        
    }
}
.bankCard_req{
    color: #666666;
}
.bankCard_hr{
    width: 100%;
    height: 1px;
    background: #C4C4C4;
    margin: 30px 0;
}
.bankCard_email{
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 10px 0;
    text-align: center;
    color: #666666;
    outline: none;
    width: 100%;
    margin-top: 5px;
}
.bankCard_button{
    width: 100%;
    height: 72px;
    margin-top: 25px;
    padding: 20px 0;
    color: #FFFFFF;
    font-size: 21px;
    font-weight: bold;
    background: #8D191D;
    border-radius: 15px;
    border: none;
}
.bankCard_button.disabled{
    background: #333;
    opacity: 0.3;
}
.bankCard_error{
    margin-top: 15px;
    border: 1px solid red;
    color: red;
    font-size: 11px;
    line-height: 120%;
    padding: 16px;
    background-color: rgba(255, 0, 0, 0.1);
}