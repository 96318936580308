@font-face{
    font-family: 'sf-ui-thin';
    font-weight: 200;
    font-display: swap;
    src: url("../assets/fonts/sf-ui-display-thin.woff") format("woff");
}
@font-face{
    font-family: 'sf-ui-regular';
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/SF-UI-Display-Regular.woff") format("woff");
}
@font-face{
    font-family: 'sf-ui-medium';
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/sf-ui-display-medium.woff") format("woff");
}
@font-face{
    font-family: 'sf-ui-semibold';
    font-weight: 600;
    font-display: swap;
    src: url("../assets/fonts/sf-ui-display-semibold.woff") format("woff");
}
@font-face{
    font-family: 'sf-ui-bold';
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/sf-ui-display-bold.woff") format("woff");
}
@font-face{
    font-family: 'pf-regular-pro';
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/PFRegalTextPro-Black.woff") format("woff");
}
