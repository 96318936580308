.cart__form {
    position: relative;
    margin-top: 30px;
}
.cart {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 375px;


    &__header {
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;

        &-text {
            font-size: $fsprice;
            font-family: $bold;

            span {
                font-size: 21px;
                color: $red;
                margin: 0 4px;
            }
        }
    }
    &__choice {
        padding: 8px;
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        font-family: $bold;
        font-size: $fstitle;
        margin-top: 30px;
        background-color: $grayEF;

        &__item {
            flex: 1;
            cursor: pointer;
            padding: 10px 0;
            text-align: center;
            color: $gray6;
            border-radius: 50px;
            &.active {
                background-color: $red;
                color: #fff;
            }
        }
    }
    &__goods-list {
        margin-top: 10px;
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 15px 20px 20px;
        border-bottom: 1px solid $grayC;

        &__validate {
            color: #ff0000;
            margin-top: 10px;
        }
        .count-error {
            color: #ff0000;
            margin-top: 15px;
        }
        &.error {
            background-color: rgba(255, 0, 0, 0.1);
            border-left: 3px solid #ff0000;
        }
        &__title {
            max-width: 160px;
            font-family: $bold;
            font-size: $fstitle;
            line-height: 120%;
            color: $gray3;
        }
        &__img-wrap {
            width: 72px;
            height: 72px;

            img {
                width: 100%;
                object-fit: cover;
            }
        }
        &__price {
            font-size: $fsstocktitle;
            color: $red;
            font-family: $bold;
        }
        &__count {
            font-size: $fsstocktitle;
        }
        &__increment,
        &__decriment {
            cursor: pointer;
        }
        &__count-option {
            background-color: $grayEF;
            height: 30px;
            padding: 0 11px;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 84px;
        }
        &__middle {
            display: flex;
            width: 160px;
            flex-direction: column;
            justify-content: space-between;
        }
        &__remove {
            align-self: flex-end;
            width: 30px;
            height: 30px;
            border-radius: 8px;
            background-color: $grayEF;
            border: none;
            cursor: pointer;
        }
        &__right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &__disable {
            cursor: no-drop;
            img {
                opacity: 0.3;
            }
        }
    }
    &__order {
        &-btn {
            padding: 25px 0;
            width: 100%;
            background-color: $red;
            color: $white;
            cursor: pointer;
            font-size: 21px;
            font-family: $bold;
            border-radius: 90px;
            border: 2px solid $red;
            &[disabled] {
                background-color: $white;
                color: $grayC;
                border: 2px solid #EFEFEF;
            }
        }
        &__sale {
            font-family: $bold;
            font-size: $fstitle;
            color: $gray6;
        }
        &__total {
            margin-top: 10px;
            &-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
            }
            &-title {
                color: $gray3;
                font-size: 25px;
            }
            &-sum {
                color: $red;
                font-family: $bold;
                font-size: 25px;
            }
        }
        &__delivery {
            &-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &-title {
                color: $gray3;
                font-family: $semibold;
                font-size: 12px;
            }
            &-sum {
                color: $green;
                font-family: $bold;
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }
    .point-closed-cart-container {
        margin-top: 28px;
        margin-bottom: 15px;
				margin-left: 10px;
				margin-right: 25px;
        border: 2px solid #CCCCCC;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        .top-text {
            color: #333333;
            font-size: 14px;
            font-family: $semibold;
            flex-direction: column;
            align-items: center;
            white-space: nowrap;
            line-height: 120%;
            text-align: center;
            span {
                color: #8D191D;
            }
        }
        .text-secondary {
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #ABABAB;
            margin-top: 9px;
        }
    }
    .text-secondary-closed {
        font-size: 12px;
        line-height: 14px;
        color: #ABABAB;
        margin-top: 30px;
    }
    &__order-info {
        margin-top: 20px;
        &__title {
            color: $gray6;
        }
        &__sale-input {
            padding: 9px 14px;
            max-width: 118px;
            border: none;
            border-radius: 8px;
            background-color: $grayEF;
            outline: none;
            font-size: $fstext;
            font-family: $regular;

            &::placeholder {
                font-size: $fstext;
                font-family: $regular;
            }
        }
    }
    &__order__sale-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__memo {
        font-size: $fstitle;
        line-height: 120%;
        color: $gray6;

        &__banner {
            &.pickup {
                color: #000000;
                margin-top: 30px !important;
                border-radius: 8px;
                font-size: 13px;
                line-height: 14px;
                font-family: sf-ui-medium, sans-serif;

                .city-info {
                    margin-top: 7px;
                    height: 40px;
                    background-color: $green;
                    color: $white;
                    font-family: $regular;
                }
            }
            &.delivery {
                display: flex;
                align-items: center;
                color: $white;
                background-color: $green;
                margin-top: 10px;
                height: 60px;
                font-size: 12px;
                line-height: 14px;
                padding: 13px 13px 13px 26px;
                img {
                    width: 26px;
                    height: 16px;
                }
                div {
                    margin-left: 16px;
                    font-size: 13px;
                    line-height: 17px;
                }
            }

            .mark {
                background: url('../assets/i/cart/mark-white.svg');
                display: inline-block;
                width: 10.5px;
                height: 14px;
                margin: 13px;
            }
            .city-info {
                display: flex;
                align-items: center;
                width: 100%;
                height: 35px;
                background: #EFEFEF;
                border-radius: 60px;
                color: #000000;
                font-size: 12px;
                margin-top: 15px;
            }
        }
        b {
            padding: 0 4px;
            font-size: $bold;
            font-weight: 600;
        }
        .free-deliv {
            font-size: 12px;
            line-height: 14px;
            font-family: $regular;
            color: $gray6;
            .ok-icon {
                display: inline-block;
                height: 13.6px;
                width: 17px;
                background-image: url('../assets/i/hink/ok-icon.svg');
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
            }
        }
    }
    &__form {
        .custom_checkbox {
            padding: 0 15px;
        }
        textarea {
            max-width: 345px;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
        textarea::placeholder {
            color: #ABABAB;
        }
        &__table {
            margin-bottom: 22px;
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;

            &__title {
                font-family: $bold;
                color: $gray3;
                display: flex;
                &:after {
                    content: "";
                    margin-left: 15px;
                    width: 1px;
                    height: 16px;
                    background-color: $grayC;
                }
                &:before {
                    margin-right: 22px;
                    content: "";
                    width: 16px;
                    height: 14px;
                    background-image: url("../assets/i/table-red.svg");
                }
            }
            &__value {
                font-size: $fstitle;
                color: $gray6;
                min-width: 144px;
                padding-left: 15px;
            }
            &__qrcode {
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .checkout_box {
        height: 100vh;
        background: url("../assets/img/starik.png") right bottom no-repeat;
    }
    .checkout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 30%;
        align-items: center;
        width: 100%;
        font-size: 15px;
        line-height: 18px;
        color: #333333;
        background: url("../assets/img/starik.png") right bottom no-repeat;
        position: absolute;
        height: 100%;
        min-height: 500px;
        z-index: 1;
        & > * {
            text-align: center;
        }
        img {
            width: 72px;
            min-width: 72px;
            height: 72px;
        }
        &__title {
            color: #8d191d;
            font-size: 25px;
            margin: 20px 0;
        }
        &__order {
            font-weight: bold;
            margin-bottom: 20px;
            color: #000;
        }
        &__dash {
            font-weight: 300;
        }
    }
    .clear {
        min-width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grayEF;
        cursor: pointer;
        border-radius: 8px;
        margin-right: 20px;
    }
    .point-closed {
        .clear {
            cursor: auto;
        }
        .cart__order-btn {
            cursor: auto;
        }
    }
}
.order-btn-pointclosed[disabled] {
	width: 90%;
	background: #EFEFEF;
	border-radius: 90px;
	height: 70px;
	padding: 10px 0;
	text-align: center;
	color:  #ABABAB;
	border: none;
	cursor: pointer;
	font-weight: 500;
	font-size: 21px;
	font-family: $bold;
	margin-bottom: 30px;
	margin-left: 10px;
	
}
.order-container{
	min-height: 100% !important;
  height: 100% !important;
}

.hot_box {
    position: relative;
}
.hot {
    width: 72px;
    height: 70px;
    position: absolute;
    left: -70px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
		z-index: 99;
}

.disclaimer {
    position: absolute;
    color: #FF0000;
    bottom: 1px;
    font-size: 10px;
    display: flex;
    margin-left: 15%;
}
.mapsPopup {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 375px;
    background-color: #FFFFFF;
    padding-top: 27px;
    padding-bottom: 40px;
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
    z-index: 9999;
    font-size: 19px;
    box-shadow: -1.45153px -30.966px 60px rgba(0, 0, 0, 0.25), -0.559108px -11.9276px 19.1111px rgba(0, 0, 0, 0.151852), -0.118273px -2.52316px 4.88889px rgba(0, 0, 0, 0.0981481);
    border-radius: 30px 30px 0px 0px;
    transition: bottom 0.6s cubic-bezier(0, -0.02, 0.54, 1.18);

    &__adress-box {
        margin-right: 10px;
        width: 100%;
    }
    &__adress {
        position: absolute;
        height: 60px;
        z-index: 99;
        background: $white;
        border-radius: 80px;
        display: flex;
        align-items: center;
        width: 90%;
        padding: 10px 0;
        margin-left: 22px;
        margin-top: 46px;
        color: #333333;
        font-size: 15px;
        box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.04), 0px 2.6963px 5.0963px rgba(0, 0, 0, 0.0242963), 0px 0.57037px 1.3037px rgba(0, 0, 0, 0.0157037);;
        img {
            margin-left: 20px;
            min-width: 13.75px;
            height: 20px;
        }
    }
    &__value {
        margin-left: 15px;
        font-size: 12px;
    }
    &__input {
        color: #666666;
        width: 100%;
        padding: 15px;
        border: none;
        background: transparent;
        &:focus {
            border: none;
            background: none;
            outline: none;
        }
    }

    &__button {
        position: absolute;
        bottom: 68px;
        right: 16px;
        height: 72px;
        width: 100%;
        color: $white;
        padding: 23px 0;
        cursor: pointer;
        border-radius: 100px;
        font-size: 21px;
        font-family: $bold;
        font-weight: bold;
        text-align: center;
        background-color: $red;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        outline: none;
        border: none;
        z-index: 99;
        box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.04), 0px 2.6963px 5.0963px rgba(0, 0, 0, 0.0242963), 0px 0.57037px 1.3037px rgba(0, 0, 0, 0.0157037);
        svg {
            fill: #FFFFFF;
        }
        &[disabled] {
            background-color: #EFEFEF;
            color: #CCCCCC;
            svg {
                fill: #CCCCCC;
            }
        }
    }
    .noactive {
        background-color: #fdc935;
        opacity: 0.3;
        cursor: no-drop;
    }
}
.address-select-map .welcome {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.address-select-map .welcome__header {
    padding-top: 25px;
    background-color: transparent;
}
.popupFixCart {
    padding: 0 35px 30px 35px;
    margin-left: -20px;
    background-color: #efefef;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: -1.45153px -30.966px 60px rgba(0, 0, 0, 0.25),
        -0.559108px -11.9276px 19.1111px rgba(0, 0, 0, 0.151852),
        -0.118273px -2.52316px 4.88889px rgba(0, 0, 0, 0.0981481);
    z-index: 999;
    animation: 0.2s show ease;
    &_box {
        position: relative;
        padding-top: 35px;
        &__plash {
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 72px;
            height: 4px;
            background: #cccccc;
            opacity: 0.5;
            border-radius: 50px;
        }
        &__title {
            margin-top: 0px;
            margin-bottom: 20px;
            font-family: sf-ui-bold;
            cursor: pointer;
            img {
                margin-right: 25px;
            }
            span {
                color: #333333;
                font-size: 17px;
                font-weight: bold;
            }
        }
        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        &__list-item {
            cursor: pointer;
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #cccccc;
            svg {
                fill: #333333;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .active_item {
            svg {
                fill: #8d191d;
            }
            .popupFixCart_box_item__text {
                color: #8d191d;
            }
        }

        &_item__icon {
            display: inline-block;
            margin-right: 20px;
        }
        &_item__text {
            display: inline-block;
            font-family: sf-ui-bold;
            color: #333333;
            font-size: 13px;
            font-weight: bold;
        }
    }
}
.popupFixCart {
    position: fixed;
    bottom: calc(14vh - 15px);
    width: 100%;
    max-width: 375px;
}
.popupToBottCart {
    position: absolute;
    bottom: 0;
    width: 360px;
    padding: 0 25px 30px 25px;
}

.special-offer-container {
    width: 100%;
    height: 60px;
    background-color: #EFEFEF;
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 20px;
    .hink-free-container {
        background: #558950;
        width: 40px;
        height: 40px;
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        .hink-free {
            display: inline-block;
            height: 20px;
            width: 19.61px;
            background-image: url('../assets/i/hink/hink-free.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            justify-content: center;
            align-items: center;
        }
    }
    .hink-number-left {
        width: 72px;
        height: 40px;
        background-color: #fff;
        border-radius: 9px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-right: 15px;
        .hink-offer {
            display: inline-block;
            height: 20px;
            width: 20px;
            background-image: url('../assets/i/hink/hink-offer.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            justify-content: center;
            align-items: center;
        }
        .number {
            font-family: Manrope, sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #666666;
        }
    }
    .hink-left-text {
        font-family: Manrope, sans-serif;
        color: #333333;
        font-size: 14px;
        span {
            color: #ABABAB;
            font-size: 11px;
            line-height: 11px;
        }
    }
    .text-container {
        display: flex;
        justify-content: space-between;
        width: 85%;
        align-items: center;
        .ok-icon {
            display: inline-block;
            height: 13.6px;
            width: 17px;
            background-image: url('../assets/i/hink/ok-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            justify-content: center;
            align-items: center;
        }
    }
}

box_checkbox{
	margin-top: 25px;
}
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
		font-size: 12px;
		line-height: 30px;
		color: #333333;
		span{
			position: relative;
			top: 10px;
			margin-top: 10px;
		}
		a{
			font-weight: 600;
			color: #333333;
			&:hover{
				text-decoration: none;
			}
		}
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    background: white;
		border: 2px solid #CCCCCC;
		border-radius: 8px;
  }



  // Box focus
  &:focus + label:before {
    border: 2px solid #CCCCCC;
  }

  // Box checked
  &:checked + label:before {
    background: #fff;
  }



  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    background: white;
    width: 15px;
    height: 12px;
    background: url("../assets/img/ok-icon.png");
  }
}

@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 560px) and (orientation: portrait) {
    .mapsPopup {
        bottom: 0;
    }
    .popupToBottCart {
        width: 375px;
    }
}
@media screen and (max-width: 360px) {
    .cart__order-btn {
        width: 320px;
    }
}
