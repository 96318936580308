.error-container {
  height: 100vh;
  width: 100%;
	padding: 0 20px;
  background-color: $white;
  .error-img {
    background: url("../assets/img/errors/error404.svg") center center no-repeat;
    height: 205px;
    width: 203px;
    display: block;
    margin-top: 38%;
    margin-left: 23%;
  }
  .error-server-img {
    background: url("../assets/img/errors/error500.svg") center center no-repeat;
    height: 205px;
    width: 203px;
    display: block;
    margin-top: 38%;
    margin-left: 23%;
  }
  h2 {
    font-family: sf-ui-bold, sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 110%;
    margin-top: 12%;
    text-align: center;
    &.server {
      margin-top: 0;
    }
  }
  div {
    
    font-size: 13px;
    line-height: 120%;
    margin-top: 8px;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 13%;
    .hink-spoon-btn {
      font-size: 17px;
      line-height: 100%;
      color: #ABABAB;
      background-color: #EFEFEF;
      border: none;
      outline: none;
      border-radius: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-align: center;
      font-weight: 500;
      transition: all .3s ease-in-out;
      width: 275px;
      height: 56px;
    }

    .reload-page-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #8d191d;
      cursor: pointer;
      border-radius: 100px;
      font-size: 17px;
      text-align: center;
      color: #fff;
      font-weight: 500;
      transition: all .3s ease-in-out;
      width: 275px;
      height: 56px;
      outline: none;
      margin-top: 15px;
      border: none;
      font-family: sf-ui-medium, sans-serif;

      &:hover {
        color: #333333;
        background-color: #FDCB3D;
      }
    }
  }
}


