@import "./fonts";
@import "./vars";
@import "./grid";
@import "./sm_components";
@import "./yaapi";

//components style
@import "./stocks";
@import "./product";
@import "./menu";
@import "./cart";
@import "./welcome";
@import "./bankcard";
@import "./loaderScreen";
@import "./errors";



*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body{
    background-color: $grayEF;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $regular;
    color: $gray3;
    font-size: $fstext;
    overflow: hidden;
}
#root{
    height: auto;
    min-height: 86vh;
    max-height: 86vh;
    width: 100%;
    max-width: 375px;
    display: flex;
    box-sizing: content-box;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;
    border: 15px solid #000;
    border-radius: 15px;
    position: relative;
}
.shop__box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &-items{
        flex-grow: 2;
        padding-bottom: 80px;
    }
}

.loader_box{
    padding-top: 5%;
}
.viewport{
    display: flex;
    align-items: center;
    max-width: 375px;
    overflow: hidden;
}
.lenta{
    display: flex;
    transition: transform 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);;
}
.select-red{
    color: $red;
}
a{
    text-decoration: none;
}
.no-scroll {
	height: 100%;
	overflow: hidden;
}


::-webkit-scrollbar-button {
    background-image:url('');
    background-repeat:no-repeat;
    width:5px;
    height:0px
    }

    ::-webkit-scrollbar-track {
        background-color:transparent
    }

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 1.5em;
        border-radius: 1.5em;
        background-color: $gray6;
    }

    ::-webkit-scrollbar-thumb:hover{
        background-color:$gray3;
    }

    ::-webkit-resizer{
        background-image:url('');
        background-repeat:no-repeat;
        width:4px;
        height:0px
    }

    ::-webkit-scrollbar{
        width: 0;
    }

    @media only screen and (max-width: 560px) and (orientation: portrait){
        body{
            overflow: auto;
        }
        #root{
            border: none;
            height: auto;
            max-height: 100vh;
            border-radius: 0;
        }
        .link-to-cart{
            width: 100%;
            max-width: 365px;
            position: fixed;
            bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .welcome button.welcome__select-adress,.welcome .welcome__select-adress{
            position: fixed;
            left: 0;
        }
        .loader_box{
            height: auto;
        }
    }
